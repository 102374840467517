'use strict';

angular.module('cpformplastApp')
  .directive('toptable', () => ({
    templateUrl: 'components/top-table/top-table.html',
    restrict: 'E',
    controller: 'TopTableController',
    controllerAs: 'toptable',
    scope: {
      tableStructure: '=tableStructure',
      objects: '=objects'
    }
  }));
